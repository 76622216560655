.modal-add-profile .modal-content {
  min-height: 550px;
  padding: 0px 20px;
  padding-top: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.emoji-container {
  margin-top: 20px;
  height: 90px;
}

.emoji-icon {
  font-size: 40px;
  cursor: pointer;
  margin: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.emoji-icon:hover {
  transform: scale(1.2) rotate(360deg);
}

.selected-emoji {
  border: 2px solid #F7F0CB;
  margin: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;

}

.profile-date {
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;
  width: 100% !important;
  height: 40px !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  padding-top: 5px;
  /* position: absolute !important; 
  z-index: 9999999 !important;
  top: 0px !important; */
}

.ant-picker-dropdown {
  position: absolute !important; /* Bootstrap modal's z-index is 1050 */
}

.active-image-profile {
  border: 2px solid #ff7700;
  border-radius: 60px;
  padding: 5px;
}
.footer-profile{
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.date-picker-popup{
  border: 2px solid red !important;
  display: block !important;
  /* z-index: 1051 !important; */
}

.react-datepicker-wrapper{
  width: 100%;
  height: 38px;
  background-color: var(--bs-body-bg) !important;
    background-clip: padding-box !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.react-datepicker__input-container input {
  border: none !important;
  width: 100% !important;
  height: 36px !important;
}