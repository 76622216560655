.jumbotron-img {
      transition: transform 0.8s ease-in-out; /* Smooth transition for the hover effect */
  }
  
  .jumbotron-img {
    height: auto;
    object-fit: cover;
    display: block;
    margin-left: auto; /* Aligns the image to the right */
    animation: upDown 3s infinite ease-in-out;
  }
  
  @keyframes upDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  