/* Stories.module.css */

.full-screen-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  backdrop-filter: blur(5px); /* Adds the frosted glass effect */
  color: rgb(253, 251, 253);
  padding: 1rem;
  text-align: center;
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 1000;
  background-color: black !important;
}

.content-stories {
  flex: 1;
  padding-top: 30px;
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #000;
}

.book-row-container {
  margin-bottom: 2rem;
}

.genre-title {
  color: white;
  margin-left: 1rem;
}

.book-row {
  display: flex;
  flex-wrap: wrap; /* allows items to wrap onto the next line */
  gap: 20px; /* adds space between items */
   justify-content: center; 
}


.book-item {
  flex: 0 0 auto;
  margin-left: 1rem; /* Margin-left instead of margin-right to align with genre title */
  transition: transform 0.3s;
}

.book-item img {
  display: block;
  width: 200px;
  height: 300px;
  border-radius: 5px;
}

.book-item:hover {
  transform: scale(1.1);
}

.book-row::-webkit-scrollbar {
  width: 2px;
}

.book-row::-webkit-scrollbar-track {
  background: #333;
}

.book-row::-webkit-scrollbar-thumb {
  background-color: #666;
}

.book-row::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

