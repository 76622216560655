.custom-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content{
  flex: 1;
  overflow-y: auto;
}

.custom-footer{
  flex-shrink: 0;
}

