.container-pdf-extract {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pdf-container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pdf-content{
  min-height: 380px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 0px 20px;
}

.pdf-img{
  /* height: 400px; */
  object-fit: fill;
  border-radius: 10px;
}

.spinner {
  width: 5rem;
  height: 5rem;
}

.imageLoader {
  width: 2rem;
  height: 2rem;
}

.loading-container span {
  font-size: 25px;
  margin-left: 15px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-container img {
  width: 100%;
  height: auto;
  aspect-ratio: 2 / 3;
  max-width: 100%;
  object-fit: cover;
}

.pdf-footer {
  display: flex;
  justify-content: center;
  flex: 0.1 1;
}

.btn-bottom {
  width: 100px;
  margin: 0px 20px;
  height: 40px;
}

.w-100px {
  width: 100px;
}


@media (min-width: 768px) and (max-width: 800px) {
  .col-md-6 {
      flex: 0 0 auto;
       width: 100%;
  }
}
/* Media queries for various screen sizes */
@media (max-width: 576px) {

  .pdf-content{
    min-height: unset;
  }
  /* Small devices (phones, 0px and up) */
  .image-container img {
    max-width: 300px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {

  /* Medium devices (tablets, 577px and up) */
  .image-container img {
    max-width: 300px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {

  /* Large devices (desktops, 769px and up) */
  .image-container img {
    max-width: 500px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {

  /* Extra large devices (large desktops, 993px and up) */
  .image-container img {
    max-width: 600px;
  }
}

@media (min-width: 1201px) {

  /* Extra extra large devices (TVs, 1201px and up) */
  .image-container img {
    max-width: 800px;
  }
}