.btn-width {
  width: 215px;
}

.input-upload {
  overflow: hidden;
  width: 200px;
}

.ml-11px {
  margin-left: 11px;
}

.navbar-black {
  background-color: black !important;
}

.logo-image {
  height: 50px;
  position: absolute;
  top: 5px;
  width: 99px;
  object-fit: cover;
}

.input-field{
  width: 400px;
}

.prominent-text{
  color: #581690;
  font-weight: bold;
  cursor: pointer;
}

.login-navigate-ref {
  width: 400px;
  text-align: center;
  line-height: 15px;
  padding-top: 20px;
}


@media only screen and (max-width: 500px) {
  .input-field{
    width: 80%;
  }
}
