.login-modal .modal-content {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 0px;
}
.header-custom{
  padding: 0px 30px
}
.header-custom p{
  font-size: 20px;
  font-weight: 700;
}

.auth-action-btn{
  width: 200px;
  background-color: #581690;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  border: 0;
}
.auth-action-btn:hover{
  background-color: #490486;
}

.close-icon{
  width: 30px;
  position: absolute;
  right: 15px;
  top: 11px;
  cursor: pointer;
}