.modal-feedback .modal-content {
  min-height: 550px;
  padding: 0px 35px;
  padding-top: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.emoji-container {
  height: 100px;
}

.emoji-icon {
  font-size: 40px;
  cursor: pointer;
  margin: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}
.emoji-icon:hover {
  transform: scale(1.2) rotate(360deg);
}

.selected-emoji {
  border: 2px solid #F7F0CB;
  margin: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
 
}
